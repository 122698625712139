export default {
    sales: [],
    closes:[],
    reverses:[],
    accounts:[],
    summary:[],
    requests:[],
    petitions:[],
    requestspay:[],
    pendingPayments:[],
    staffData:[],
    institutionsOwner:[],
    requestspaysuperadmin:[],
    canceledpayments_superadmin:[],
    sales_product:[],
    incomes_by_sales:[],
    incomes_admin: {},
    incomes_admin_split: {},
    open_tills: [],
    pending_payments_schools: [],
    canceled_payments_schools: [],
    recharges: [],
    services_summary: [],
    // school_pending_requests: []
    defaultings: [],
    school_advance_requests: [],
    school_recurring_requests: [],
    pendingNoticesRecharge: [],
    processedNoticesRecharge: [],
    recovered_penalties: [],
    marketplace_sales: []
}
