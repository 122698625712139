export default {
    SET_SALES(state, sales) {
        state.sales = sales
    },
    SET_CLOSES(state, closes) {
        state.closes = closes
    },
    SET_REVERSES(state, reverses) {
        state.reverses = reverses
    },
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts
    },
    SET_SUMMARY(state, summary) {
        state.summary = summary
    },
    SET_REQUESTS(state, requests){
        state.requests = requests
    },
    SET_COUNTRY(state, requests){
        state.requests = requests
    },
    SET_REWARDS(state, petitions){
        state.petitions = petitions
    },
    SET_REQUEST_REWARDS(state, petitions){
        state.petitions = petitions
    },
    SET_REQPAY(state, requestspay){
        state.requestspay = requestspay
    },
    SET_REQPAYSUPERADMIN(state, requestspay){
        // state.requestspaysuperadmin = requestspay
        state.requestspay = requestspay
    },
    SET_PENDINGP(state, pendingPayments){
        state.pendingPayments = pendingPayments
    },
    SET_PENDINGP_SA(state, pendingPayments){
        state.pendingPaymentsSuperAdmin = pendingPayments
    },
    SET_STAFFDATA(state, staffData){
        state.staffData = staffData
    },
    SET_REWARD_STATUS(state, payload){
        const index = state.petitions.findIndex(p => p.id == payload.id);
        Object.assign(state.petitions[index].status = payload.status)
    },
    SET_INST_OWNER(state, institutionsOwner){
        state.institutionsOwner = institutionsOwner
    },
    SET_CANCELED_PAYMENTS_SA(state, payload) {
        state.canceledpayments_superadmin = payload
    },
    SET_SALES_PRODUCT(state, payload) {
        state.sales_product = payload
    },
    SET_INCOMES_BY_SALES(state, payload) {
        state.incomes_by_sales = payload
    },
    SET_INCOMES_ADMIN(state, payload) {
        state.incomes_admin = payload
    },
    SET_INCOMES_ADMIN_SPLIT(state, payload) {
        state.incomes_admin_split = payload
    },
    SET_OPEN_TILLS(state,payload) {
        state.open_tills = payload
    },
    SET_PENDING_PAYMENTS_SCHOOLS(state, payload) {
        state.pending_payments_schools = payload
    },
    SET_SCHOOL_CANCELED_PAYMENTS(state, payload) {
        state.canceled_payments_schools = payload
    },
    SET_RECHARGES(state, payload) {
        state.recharges = payload
    },
    SET_SERVICES_SUMMARY(state, payload) {
        state.services_summary = payload
    },
    SET_DEFAULTINGS(state, payload) {
        state.defaultings = payload
    },
    SET_SCHOOL_ADVANCE_REQUESTS(state, payload) {
        state.school_advance_requests = payload
    },
    SET_SCHOOL_RECURRING_REQUESTS(state, payload) {
        state.school_recurring_requests = payload
    },
    SET_PENDING_NOTICES_RECHARGE(state, payload) {
        state.pendingNoticesRecharge = payload.map(el => ({
            ...el,
            full_name: `${el.guardian_name} ${el.guardian_last_name}`
        }))
    },
    SET_PROCESSED_NOTICES_RECHARGE(state, payload) {
        state.processedNoticesRecharge = payload.map(el => ({
            ...el,
            full_name: `${el.guardian_name} ${el.guardian_last_name}`
        }))
    },
    DELETE_PENDING_NOTICES_RECHARGE(state, payload) {
        state.pendingNoticesRecharge = state.pendingNoticesRecharge.filter(el => el.id !== payload.id)
    },
    SET_RECOVERED_PENALTIES(state, payload) {
        state.recovered_penalties = payload
    },
    SET_MARKETPLACE_SALES(state, payload) {
      state.marketplace_sales = payload
    }
}
