export default {
    // Used in Dashboard
    getSalesTiptap: state => {
        let sales = state.sales.filter(c => c.sale_type == 1)
        let min_date = new Date(Math.min(...sales.map(e => new Date(e.fecha))));
        let max_date = new Date(Math.max(...sales.map(e => new Date(e.fecha))));

        // When there is no sales in current month and or last month
        if (max_date.getMonth() != new Date().getMonth())
            max_date = new Date()

        if (min_date.getMonth() != new Date().getMonth() - 1)
            min_date = new Date(new Date().getFullYear(), new Date().getMonth() -1, 1)

        let series = []
        let day_series = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];

        //sales of min_date and max_date
        let sales_min_date = sales.filter(s => new Date(s.fecha).getMonth() == min_date.getMonth());
        let sales_max_date = sales.filter(s => new Date(s.fecha).getMonth() == max_date.getMonth());

        let series_data_min = [];
        let series_data_max = [];

        for (let i = 0; i < day_series.length; i++) {
            let day_total = 0;
            let exists = sales_min_date.filter(s => new Date(s.fecha).getDate() == day_series[i]);

            if (exists && exists.length)
                day_total = exists.reduce((total, s) => total + s.sale_total, 0).toFixed(2);

            series_data_min.push(day_total);

            day_total = 0;
            exists = false;

            exists = sales_max_date.filter(s => new Date(s.fecha).getDate() == day_series[i]);
            if (exists && exists.length)
                day_total = exists.reduce((total, s) => total + s.sale_total, 0).toFixed(2);

            series_data_max.push(day_total);
        }

        series.push({
            name: max_date.toLocaleDateString('default',{month:'long'}),
            data: series_data_max
        });

        series.push({
            name: min_date.toLocaleDateString('default',{month:'long'}),
            data: series_data_min
        });

        let statistics = {
            analyticsData: {
                thisMonth: sales_max_date.reduce((total, s) => total + s.sale_total, 0),
                lastMonth: sales_min_date.reduce((total, s) => total + s.sale_total, 0)
            },
            series: series,
        };

        return statistics;
    },

    // reversadas
    getnumbercanceled: state => {
        let reverses = state.reverses.length;
        return reverses;
    },

    // Usuarios de tiptap
    getnumberUsers: state => {
        let accounts = {};

        accounts.guardians = state.accounts ? state.accounts.filter(account => account.profile_type == 1).length : 0;
        accounts.students = state.accounts ? state.accounts.filter(account => account.profile_type == 2).length : 0;

        return accounts;
    },

    // Usuarios de tiptap
    getnumberUsersActive: state => {
        let accounts = {};

        accounts.guardians = state.accounts ? state.accounts.filter(account => account.profile_type == 1 && account.status == 2).length : 0;
        accounts.students = state.accounts ? state.accounts.filter(account => account.profile_type == 2 && (account.status == 2 || account.status == 5)).length : 0;

        return accounts;
    },

    // total a pagar
    getpayments: state => {
        let requests = state.pendingPayments;
        let total_pay = 0;

        requests.forEach(c => {
            if (c.status == 0)
                total_pay += c.total_pay;
        })

        return total_pay;
    },

    // total a pagar a administradores de escuelas por pago de servicios
    getServicePayments: state => {
        let requests = state.pending_payments_schools;
        return requests ? requests.reduce((a, c) => a + c.total_pay, 0) : 0
    },
    //total a pagar a administradores
    getcharge: state => {
        let charge = state.requestspay;
        let total_charge = 0;
        charge.forEach(c => {
            if (c.total_pay)
                total_charge += c.total_pay;
            if (c.commission_amount)
                total_charge += c.commission_amount;
        })
        return total_charge;
    },
    //total a pagar a super admin
    getchargesa: state => {
        let charge = state.pendingPaymentsSuperAdmin;
        let total_charge = 0;
        charge.forEach(c => {
            if (c.commission_amount)
                total_charge += c.commission_amount;
        })
        return total_charge;
    },
    getActiveAccounts: state => {
        return state.accounts.filter(a => a.status == 2 || a.status == 5);
    },
    getActiveGuardians: (state, getters) => {
        return getters.getActiveAccounts.filter(a => a.profile_type == 1)
    },
    getActiveStudents: (state, getters) => {
        return getters.getActiveAccounts.filter(a => a.profile_type == 2)
    },
    getDeliveredPetitions: state => {
        state.petitions.map(p => {
            p.date_status = p.approve_rejected_date ? p.approve_rejected_date : p.delivered_rejected_date
        })
        return state.petitions.filter(p => p.status != 0 && p.status != 1 && p.status != 2)
    },
    getIncomesAdmin: state => {
        let incomes = {
            analyticsData : [
                { 'type': 'Ingresos por Ventas', counts: 0, color: 'primary' },
                { 'type': 'Ingresos por Anualidad', counts: 0, color: 'warning' },
            ],
            series: [],
        }

        incomes.analyticsData[0].counts = incomes.series[0] = parseFloat(Object.values(state.incomes_admin.sales).reduce((a, b) => a + b, 0).toFixed(2));
        incomes.analyticsData[1].counts = incomes.series[1] = parseFloat(Object.values(state.incomes_admin.annuity).reduce((a, b) => a + b, 0).toFixed(2));

        return incomes;
    },
    getIncomesAdminSplit: state => {
        let incomes_split = state.incomes_admin_split;

        if (incomes_split && incomes_split.sales_profits && incomes_split.sales_profits.length) {
            incomes_split.sales_profits.forEach(profit => {
                profit.admin_incomes = (profit.tiptap_amount_commission - profit.sa_amount_commission);
            })
        }

        if (incomes_split && incomes_split.annuity_profits && incomes_split.annuity_profits.length) {
            incomes_split.annuity_profits.forEach(profit => {
                profit.admin_incomes = (profit.total_amount_annuity - profit.sa_amount_commission);
                profit.institution_name = incomes_split.institutions.find(i => i.id == profit.actual_institutions).name
            })
        }

        if (incomes_split && incomes_split.services_profits && incomes_split.services_profits.length) {
            incomes_split.services_profits.forEach(profit => {
                profit.admin_incomes = (profit.tiptap_amount_commission_guardian + profit.tiptap_amount_commission_main_institution);
            })
        }

        return incomes_split;

    },
    getGuardianManualRecharges: state => {
        const accounts = state.recharges.accounts;  //.filter(a => a.profile_type == 1)
        const recharges = state.recharges.recharges;

        let result = recharges.map(r => {
            return Object.assign(r, accounts.find(g => g.id_account == r.id_account ))
        })

        return result
    },
    getServiceDetailBySchool: state => id_school => {
        if (state.incomes_admin_split && state.incomes_admin_split.services_profits && state.incomes_admin_split.services_profits.length > 0) {
            return state.incomes_admin_split.services_profits.find(s => s.id_main_institution == id_school).detail
        } else {
            return state.requestspay.find(s => s.id_main_institution == id_school).detail
        }
    },
    getServicesSummary: state => {
        return state.services_summary
    },
    getDefaultings: state => state.defaultings,
    getPeriodTextByExpirationDate: () => expirationDate => {
      const expDate = new Date(expirationDate)

      return `${expDate.toLocaleDateString('en-US', {month: 'long'})} ${expDate.getFullYear()}`
    }
}

