import axios from '../../axios.js'

export default {
    getSales({commit}, payload) {
        return new Promise((resolve,reject) => {
            const endpoint = payload.isAdmin ? "total" : "general/" + payload.id;
            axios.get(`reports/sales/${endpoint}/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_SALES', response.data.data.sales)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getClose({commit}, payload) {
        return new Promise((resolve,reject) => {
            axios.get(`reports/till/closes/${payload.id}/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_CLOSES', response.data.data.closes)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getClosesByDate({commit}, payload) {
        return new Promise((resolve,reject) => {
            axios.get(`reports/till/closes/${payload.id}/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_CLOSES', response.data.data.closes)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getReverse({commit}, payload) {
        return new Promise((resolve,reject) => {

            axios.get(`reports/reverses/${payload.id}` + (payload.dates && payload.dates.from ? "/" + payload.dates.from : "/") + (payload.dates && payload.dates.to ? "/" + payload.dates.to : ""))
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_REVERSES', response.data.data.reverses)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getAccounts({commit, rootState}, id) {
        return new Promise((resolve,reject) => {
            axios.get(`reports/account/tiptap/${id}/1`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    const profile_types = rootState.AppData.profileTypes

                    if(response.data.data && response.data.data.length > 0) {
                        response.data.data.forEach((element) => {
                            element.birthday_date = new Date(element.birthday_date).toLocaleDateString("es-AR", this.$localeDateOptions);
                            // element.fecha_cierre = new Date(element.fecha_cierre).toLocaleDateString("es-AR", this.$localeDateOptions);
                            element.profile_type_description = profile_types.find((p) => p.profile_type == element.profile_type).profile_type_description;
                        });
                    }

                    commit('SET_ACCOUNTS', response.data.data)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getAllAccounts({commit, rootState}) {
        return new Promise((resolve,reject) => {
            axios.get(`reports/account/tiptap`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    const accounts = response.data.data.guardian.concat(response.data.data.student)
                    const profile_types = rootState.AppData.profileTypes

                    accounts.forEach((element) => {
                        element.birthday_date = new Date(element.birthday_date).toLocaleDateString("es-AR", this.$localeDateOptions);
                        element.fecha_cierre = new Date(element.fecha_cierre).toLocaleDateString("es-AR", this.$localeDateOptions);
                        element.profile_type_description = profile_types.find((p) => p.profile_type == element.profile_type).profile_type_description;
                    });

                    commit('SET_ACCOUNTS', accounts)
                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    //, rootState
    getAccountsByParams({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`reports/account/tiptap?${payload}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_ACCOUNTS', response.data.data)
                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getSummary({commit}, dates) {
        return new Promise((resolve,reject) => {
            axios.get(`reports/sales/summary/${dates.from}/${dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_ACCOUNTS', response.data.summary)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getOwnerPayments({commit},payload) {
        return new Promise((resolve,reject) => {
            axios.get(`reports/payments/owner/${payload.id_owner}/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_REQUESTS', response.data.requests)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getSchoolPayments({commit},payload) {
        return new Promise((resolve,reject) => {
            axios.get(`/digital-payment/service/payment/status/${payload.id_owner}/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_REQUESTS', response.data.requests)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getSchoolCanceledPayments({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/digital-payment/service/payment/status/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_SCHOOL_CANCELED_PAYMENTS', response.data.data)
                    resolve(response.data)
                })
                .catch(err => reject(err))
        })
    },
    // No se usa más??
    // getSuperAdminPayments({commit}) {
    //     return new Promise((resolve,reject) => {
    //         axios.get(`pos/sa/payment/cancel`)
    //             .then(response => {
    //                 if(!response.data.status)
    //                     reject(response.data.msg)

    //                 commit('SET_REQUESTS', response.data.requests)

    //                 resolve(response.data)
    //             })
    //             .catch(error => console.log(error))
    //     })
    // },
    getAdminCountry({commit}, payload) {
        return new Promise((resolve,reject) => {
            axios.get(`reports/payments/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_COUNTRY', response.data.requests)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getRequestRewards({commit}) {
        return new Promise((resolve,reject) => {
            axios.get(`reports/rewards/petitions`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    if(response.data.data.petitions && response.data.data.petitions.length) {
                        response.data.data.petitions.forEach((element) => {
                            element.date_petition = new Date(element.date_petition).toLocaleDateString('es-AR', this.$localeDateOptions);
                            element.approve_rejected_date = element.approve_rejected_date ? new Date(element.approve_rejected_date).toLocaleDateString('es-AR', this.$localeDateOptions) : null;
                            element.delivered_rejected_date = element.delivered_rejected_date ? new Date(element.delivered_rejected_date).toLocaleDateString('es-AR', this.$localeDateOptions) : null;
                            let guardian = response.data.data.student_guardian.filter(guardian => guardian.id_account == element.id_account )
                            element.id_guardian = (guardian.length > 0 ? guardian[0].id_guardian : "");
                            response.data.data.account.forEach((account) => {
                                if(element.id_account == account.id_account){
                                    element.name_account = account.name + " " + account.last_name;
                                }
                                if(account.id_account == element.id_guardian){
                                    element.name_guardian = account.name + " " + account.last_name;
                                }
                            })

                        });

                        commit('SET_REQUEST_REWARDS', response.data.data.petitions)

                    }

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getRequestPay({commit}, payload) {
        return new Promise((resolve,reject) => {
            axios.get(`/pos/payment/request/${payload}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_REQPAY', response.data.data)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getRequestPaySuperAdmin({commit}) {
        return new Promise((resolve,reject) => {
            axios.get(`/pos/sa/payment/request`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_REQPAYSUPERADMIN', response.data.data.payments)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    posRequestPay(ctx, requestpay) {
        return new Promise((resolve,reject) => {
            axios.post(`/pos/payment/request`, requestpay)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    requestPaymentSuperAdmin({commit}, requestpay) {
        return new Promise((resolve,reject) => {
            axios.post(`/pos/sa/payment/request`, requestpay)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_REQPAYSUPERADMIN', response.data.data)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getPendingPayments({commit}) {
        return new Promise((resolve,reject) => {
            axios.get(`/pos/payment/request/pending`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_PENDINGP', response.data.data.requests)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getPendingPaymentsSuperAdmin({commit}) {
        return new Promise((resolve,reject) => {
            axios.get(`/pos/payment/pending`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_PENDINGP_SA', response.data.data)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    cancelPaymentRequest(context, payload) {
        return new Promise((resolve,reject) => {
            axios.post(`/pos/payment/request/cancel`, payload)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    cancelPaymentRequestSuperAdmin(context, payload) {
        return new Promise((resolve,reject) => {
            axios.post(`/pos/payment/pay`, payload)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getSuperAdminCanceledPayments({commit}, payload) {
        return new Promise((resolve,reject) => {
            axios.get(`/reports/sa/payment/paid/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_CANCELED_PAYMENTS_SA', response.data)
                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getStaffData({commit}, id) {
        return new Promise((resolve,reject) => {
            axios.get(`/pos/staff/${id}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_STAFFDATA', response.data.data)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
/*     posPendingPayments({commit}, pendingpayments) {
        return new Promise((resolve,reject) => {
            axios.post(`/pos/payment/request/cancel`, {pendingpayments})
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_POSPENDINGP', response.data.pospendingpayments)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    }, */
    // button change status (petitions)
    RejectedRewardsPetitions({commit}, id) {
        return new Promise((resolve,reject) => {
            axios.post(`/pos/rewards/rejected/${id}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)
                    let payload = {id:id, status:2}
                    commit('SET_REWARD_STATUS',payload)
                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    GiveRewardsPetitions({commit}, id) {
        return new Promise((resolve,reject) => {
            axios.post(`/pos/rewards/delivered/${id}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)
                    let payload = {id:id, status:1}
                    commit('SET_REWARD_STATUS',payload)
                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getInstitutionsOwner({commit}, id) {
        return new Promise((resolve,reject) => {
            axios.get(`/reports/institutions/owner/${id}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_INST_OWNER', response.data.institutionsOwner)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    salesByProduct({commit, dispatch, rootGetters}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/reports/sales/product/${payload.id}/${payload.dates.from}/${payload.dates.to}`)
                .then((response) => {
                    return new Promise((resolve) => {
                        if(rootGetters['dataListCategories/getCategories'] === undefined || (rootGetters['dataListCategories/getCategories'] && rootGetters['dataListCategories/getCategories'].length == 0)) {
                            return dispatch('dataListCategories/getCategories',null, {root: true})
                            .then(() => {
                                return resolve(response)
                            })
                        } else {
                            return resolve(response)
                        }
                    })
                })
                .then(response => {
                    const categories = rootGetters['dataListCategories/getCategories']
                    response.data.data.forEach(element => {
                        var cat_element = categories.find((category) => category.id == element.product_category)
                        element.category_name = cat_element ? cat_element.name : ""
                    });
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_SALES_PRODUCT', response.data.data)
                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    incomesBySales({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/reports/sales/date/${payload.id}/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_INCOMES_BY_SALES')
                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    incomesAdminSplit({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/reports/incomes/${payload.type}/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_INCOMES_ADMIN_SPLIT', response.data.data)
                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getOpenTills({commit}, payload) {
        return new Promise((resolve,reject) => {
            axios.get(`/pos/till/open/${payload.id}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_OPEN_TILLS', response.data.data)
                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getTillDetail(context, id_till_init) {
        return new Promise((resolve, reject) => {
            axios.get(`/reports/till/detail/${id_till_init}`)
            .then(response => {
                if(!response.data.status)
                    reject(response.data.msg)

                resolve(response.data)
            })
            .catch(error => console.log(error))
        })
    },
    getTillCloseDetail(context, id_till_init) {
        return new Promise((resolve, reject) => {
            axios.get(`/pos/till/close/total/${id_till_init}`)
            .then(response => {
                if(!response.data.status)
                    reject(response.data.msg)

                resolve(response.data)
            })
        })
    },
    closeTill(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/pos/till/close`, payload)
            .then(response => {
                if(!response.data.status)
                    reject(response.data.msg)

                resolve(response.data)
            })
            .catch(error => console.log(error))
        })
    },
    getRequestPayServices({commit}, payload) {
        return new Promise((resolve,reject) => {
            axios.get(`/digital-payment/service/payment/request/${payload}/1`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_REQPAY', response.data.data)

                    resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    serviceRequestPay(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/digital-payment/service/payment/request/${payload.id_owner}/1`, {id_establishment: payload.id_establishment})
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    // commit('SET_SCHOOL_PENDING_REQUESTS', response.data.data)
                    resolve(response.data)
                })
                .catch(err => console.log(err))
        })
    },
    getPendingPaymentsSchools({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/digital-payment/service/payment/request/pending/1')
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_PENDING_PAYMENTS_SCHOOLS', response.data.data.requests)
                    return resolve(response.data)
                })
        })
    },
    cancelPaymentRequestSchool(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/digital-payment/service/payment/cancel`, payload)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    resolve(response.data)
                })
                .catch(err => reject(err))
        })
    },
    getSchoolServicePayments(ctx, payload) {
        return new Promise((resolve, reject) => {
            // ToDo: nueva estructura /reports/services/paid/:id_establishment_type/:id_establishment/:from/:to
            axios.get(`/reports/services/paid/1/${payload.id_establishment}/${payload.dates.from}/${payload.dates.to}`)
                .then(response =>{
                    if(!response.data.status)
                        reject(response.data.msg)

                    if(response.data.data.paid && response.data.data.paid.length > 0) {
                        response.data.data.paid.forEach(element => {
                            response.data.data.accounts.forEach((account) => {
                                if(element.id_guardian == account.id_account){
                                    element.guardian_name = account.name + " " + account.last_name;
                                }
                                if(element.id_student == account.id_account){
                                    element.student_name = account.name + " " + account.last_name;
                                }
                            })
                        })
                    }

                    resolve(response.data)
                })
                .catch(err => console.log(err))
        })
    },
    getAllServicePayments(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/digital-payment/service/payment/status/${payload.dates.from}/${payload.dates.to}`)
                .then(response =>{
                    if(!response.data.status)
                        reject(response.data.msg)

                    // response.data.data.paid.forEach(element => {
                    //     response.data.data.accounts.forEach((account) => {
                    //         if(element.id_guardian == account.id_account){
                    //             element.guardian_name = account.name + " " + account.last_name;
                    //         }
                    //         if(element.id_student == account.id_account){
                    //             element.student_name = account.name + " " + account.last_name;
                    //         }
                    //     })
                    // })

                    // resolve(response.data)
                })
                .catch(err => console.log(err))
        })
    },
    guardianRecharge(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/pos/guardian/recharge`, payload)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    resolve(response.data)
                })
        })
    },
    getGuardianRecharges({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/reports/recharges/${payload.dates.from}/${payload.dates.to}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    if(response.data.data.recharges.length) {
                        response.data.data.recharges.forEach(r => {
                            r.sale_date = new Date(r.sale_date).toLocaleDateString('es-AR', {year: "numeric", month: "2-digit", day: "2-digit", timeZone: "UTC"})
                        })
                    }

                    commit('SET_RECHARGES', response.data.data)
                    resolve(response.data)
                })
                .catch(err => reject(err))
        })
    },
    getPendingNoticesRecharge({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/ach/recharges?${payload}`)
                .then(response => {
                    if (!response.data.status) {
                        reject(response.data.msg)
                    }
                    commit('SET_PENDING_NOTICES_RECHARGE', response.data.data)
                    return resolve(response.data)
                })
                .catch(err => console.log(err))
        })
    },
    getProcessedNoticesRecharge({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/ach/recharges?${payload}`)
                .then(response => {
                    if (!response.data.status) {
                        reject(response.data.msg)
                    }
                    commit('SET_PROCESSED_NOTICES_RECHARGE', response.data.data)
                    return resolve(response.data)
                })
                .catch(err => console.log(err))
        })
    },
    approveNoticesRecharge({ commit }, achData) {
        return new Promise((resolve, reject) => {
            axios.post('/api/ach/recharges/approve', {
                id: achData.id,
                reference: achData.reference
            })
            .then(response => {
                if(!response.data.status) {
                    return reject(response.data.msg)
                }
                commit('DELETE_PENDING_NOTICES_RECHARGE', achData)
                resolve(response.data)
            })
            .catch(err => console.log(err))
        })
    },
    rejectNoticesRecharge({ commit }, achData) {
        return new Promise((resolve, reject) => {
            axios.post('/api/ach/recharges/reject', {
                id: achData.id,
                reference: achData.reference
            })
                .then(response => {
                    if(!response.data.status) {
                        return reject(response.data.msg)
                    }
                    commit('DELETE_PENDING_NOTICES_RECHARGE', achData)
                    resolve(response.data)
                })
                .catch(err => console.log(err))
        })
    },
    getServicesSummary({ commit } , dates) {
        return new Promise((resolve, reject) => {
            axios.get(`/reports/services/payment/summary/1/${dates.from}/${dates.to}`)
            .then(response => {
                if(!response.data.status)
                    reject(response.data.msg)

                commit('SET_SERVICES_SUMMARY', response.data.data)
                return resolve(response.data)
            })
            .catch(err => console.log(err))
        })
    },
    getDefaultings({ commit }, idSchool) {
        return new Promise((resolve, reject) => {
            axios.get(`/reports/defaulters/${idSchool}`)
            .then(response => {
                if(!response.data.status)
                    reject(response.data.msg)

                commit('SET_DEFAULTINGS', response.data.data)
                resolve(response.data.data)
            })
            .catch(err => console.log(err))
        })
    },
    getSchoolAdvanceRequestByStatus({ commit }, idStatus) {
        // id_status 0 = Solicitado, 1 = Pagado
        return new Promise((resolve, reject) => {
            axios.get(`/digital-payment/service/advances/${idStatus}`)
                .then(response => {
                    if(!response.data.status)
                        reject(response.data.msg)

                    commit('SET_SCHOOL_ADVANCE_REQUESTS', response.data.data.requests)
                    resolve(response.data)
                })
        })
    },
    getSchoolAdvanceRequests({ commit }, params) {
      return new Promise((resolve, reject) => {
          axios.get(`/digital-payment/service/advances?${params}`)
              .then(response => {
                  if(!response.data.status)
                      return reject(response.data.msg)

                  commit('SET_SCHOOL_ADVANCE_REQUESTS', response.data.data.requests)
                  return resolve(response.data)
              })
              .catch(err => console.log(err))
      })
    },
    cancelAdvanceRequest(context, payload) {
        return new Promise((resolve,reject) => {
            axios.post(`/digital-payment/service/payment/advance/cancel`, payload)
                .then(response => {
                    if(!response.data.status)
                        return reject(response.data.msg)

                    return resolve(response.data)
                })
                .catch(error => console.log(error))
        })
    },
    getRecurringServicesPendingRequests({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get(`/digital-payment/service/payment/request/pending/2`)
                .then(response => {
                    if(!response.data.status)
                        return reject(response.data.msg)

                    commit('SET_SCHOOL_RECURRING_REQUESTS', response.data.data.requests)
                    return resolve(response.data)
                })
                .catch(err => console.log(err))
        })
    },
    getRecurringServicesRequestsByStatus({ commit }, idStatus) {
        return new Promise((resolve, reject) => {
          // Endpoint changes from /digital-payment/service/recurring to /api/digital-payment/service/recurring?id=20&id_establishment=15&period='2023-05'&status=1
            axios.get(`/digital-payment/service/recurring/?status=${idStatus}`)
                .then(response => {
                    if(!response.data.status)
                        return reject(response.data.msg)

                    commit('SET_SCHOOL_RECURRING_REQUESTS', response.data.data.requests)
                    return resolve(response.data)
                })
                .catch(err => console.log(err))
        })
    },
    getRecurringServicesRequests({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(`/digital-payment/service/recurring?${params}`)
                .then(response => {
                    if(!response.data.status)
                        return reject(response.data.msg)

                    commit('SET_SCHOOL_RECURRING_REQUESTS', response.data.data.requests)
                    return resolve(response.data)
                })
                .catch(err => console.log(err))
        })
    },
    getRecurringServicesRequestDetail( context, id) {
      return new Promise((resolve, reject) => {
          axios.get(`/digital-payment/service/recurring/detail/${id}`)
              .then(response => {
                  if(!response.data.status)
                      return reject(response.data.msg)

                  // commit('SET_SCHOOL_RECURRING_REQUESTS', response.data.data.requests)
                  return resolve(response.data)
              })
              .catch(err => console.log(err))
      })
    },
    getAdvanceRequestDetail( context, id) {
      return new Promise((resolve, reject) => {
          axios.get(`/digital-payment/service/advances/detail/${id}`)
              .then(response => {
                  if(!response.data.status)
                      return reject(response.data.msg)

                  // commit('SET_SCHOOL_RECURRING_REQUESTS', response.data.data.requests)
                  return resolve(response.data)
              })
              .catch(err => console.log(err))
      })
    },
    getRecurringServicesWithholding( context, params) {
      return new Promise((resolve, reject) => {
          axios.get(`/digital-payment/service/withholdings?${params}`)
              .then(response => {
                  if(!response.data.status)
                      return reject(response.data.msg)

                  return resolve(response.data)
              })
              .catch(err => console.log(err))
      })
    },
    getRecurringServicesWithholdingReturn( context, params) {
      return new Promise((resolve, reject) => {
        axios.get(`/digital-payment/service/withholdings/return?${params}`)
            .then(response => {
                if(!response.data.status)
                    return reject(response.data.msg)

                return resolve(response.data)
            })
            .catch(err => console.log(err))
      })
    },
    getStudentsAccount( context, params) {
      return new Promise((resolve, reject) => {
        axios.get(`/reports/students/account?${params}`) //?id_school=10&document='12354654'&period='2023-04'&payment_status=0&payment_type=1
            .then(response => {
                if(!response.data.status)
                    return reject(response.data.msg)

                return resolve(response.data)
            })
            .catch(err => console.log(err))
      })
    },
    addRetention( context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/digital-payment/service/withholdings/add`, payload)
            .then(response => {
                if(!response.data.status)
                    return reject(response.data.msg)

                return resolve(response.data)
            })
            .catch(err => console.log(err))
      })
    },
    returnRetention(_, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/api/digital-payment/service/withholdings/return', payload)
                .then(response => {
                    if (!response.data.status) return reject(response.data.msg)
                    return resolve(response.data)
                })
                .catch(err => console.error(err))
        })
    },
    editRetention( context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/digital-payment/service/withholdings/mod`, payload)
            .then(response => {
                if(!response.data.status)
                    return reject(response.data.msg)

                return resolve(response.data)
            })
            .catch(err => console.log(err))
      })
    },
    removeRetention( context, idRetention) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/digital-payment/service/withholdings/remove/${idRetention}`)
            .then(response => {
                if(!response.data.status)
                    return reject(response.data.msg)

                return resolve(response.data)
            })
            .catch(err => console.log(err))
      })
    },
    getRecoveredPenalties({ commit }, params) {
      return new Promise((resolve, reject) => {
          axios.get(`/reports/penalties?${params}`)
          .then(response => {
              if(!response.data.status)
                  reject(response.data.msg)

              commit('SET_RECOVERED_PENALTIES', response.data.data)
              resolve(response.data.data)
          })
          .catch(err => console.log(err))
      })
  },
  // Marketplace
  getMarketplaceSalesByStore({ commit }, payload) {
    return new Promise((resolve, reject) => {
        axios.get(`/reports/marketplace/sales/${payload.id}?${payload.params}`)
        .then(response => {
            if(!response.data.status)
                reject(response.data.msg)

            commit('SET_MARKETPLACE_SALES', response.data.data)
            resolve(response.data.data)
        })
        .catch(err => console.log(err))
    })
  },
  getMarketplaceSales({ commit }, payload) {
    return new Promise((resolve, reject) => {
        axios.get(`/reports/marketplace/sales?${payload}`)
        .then(response => {
            if(!response.data.status)
                reject(response.data.msg)

            commit('SET_MARKETPLACE_SALES', response.data.data)
            resolve(response.data.data)
        })
        .catch(err => console.log(err))
    })
  },
  getMarketplacePendingPayments({commit}) {
    return new Promise((resolve,reject) => {
        axios.get(`/marketplace/payment/request?status=0`)
            .then(response => {
                if(!response.data.status)
                    reject(response.data.msg)

                commit('SET_PENDINGP', response.data.data.requests)

                resolve(response.data)
            })
            .catch(error => console.log(error))
    })
  },
  cancelPaymentRequestStore(ctx, payload) {
    return new Promise((resolve, reject) => {
        axios.put(`/marketplace/payment/request`, payload)
            .then(response => {
                if(!response.data.status)
                    reject(response.data.msg)

                resolve(response.data)
            })
            .catch(err => reject(err))
    })
  },
  getMarketplacePaymentRequest({commit}, payload) {
    return new Promise((resolve,reject) => {
        axios.get(`/marketplace/payment/request/${payload}`)
            .then(response => {
                if(!response.data.status)
                    reject(response.data.msg)

                commit('SET_REQPAY', response.data.data)

                resolve(response.data)
            })
            .catch(error => console.log(error))
    })
  },
  addMarketplacePaymentRequest(ctx, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/marketplace/payment/request/${payload}`)
          .then(response => {
              if(!response.data.status)
                  reject(response.data.msg)

              resolve(response.data)
          })
          .catch(err => console.log(err))
    })
  },
  getMarketplacePayments({commit}, payload) {
    // /marketplace/payment/request/
    return new Promise((resolve,reject) => {
      axios.get(`/marketplace/payment/request/${payload}`)
          .then(response => {
              if(!response.data.status)
                  reject(response.data.msg)

              commit('SET_REQUESTS', response.data.data)

              resolve(response.data)
          })
          .catch(error => console.log(error))
    })
  },
  getDirectPaymentCashOutRequests({commit}, payload) {
    // /marketplace/payment/request/
    return new Promise((resolve,reject) => {
      axios.get(`/digital-payment/direct-payment/request/${payload}`)
          .then(response => {
              if(!response.data.status)
                  reject(response.data.msg)

              commit('SET_REQUESTS', response.data.data)

              resolve(response.data)
          })
          .catch(error => console.log(error))
    })
  },
  getDirectPaymentCashOutRequestDetail(ctx , payload) {
    // /marketplace/payment/request/
    return new Promise((resolve,reject) => {
      axios.get(`/digital-payment/direct-payment/request/detail/${payload}`)
          .then(response => {
              if(!response.data.status)
                  reject(response.data.msg)

              // commit('SET_REQUESTS', response.data.data)

              resolve(response.data)
          })
          .catch(error => console.log(error))
    })
  },
  cancelPaymentRequestDirectPayment(ctx, payload) {
    return new Promise((resolve, reject) => {
        axios.put(`/digital-payment/direct-payment/request/cancel`, payload)
            .then(response => {
                if(!response.data.status)
                    reject(response.data.msg)

                resolve(response.data)
            })
            .catch(err => reject(err))
    })
  },
  getDirectPayments(ctx , payload) {
    // /marketplace/payment/request/
    return new Promise((resolve,reject) => {
      axios.get(`/digital-payment/direct-payment/${payload}`)
          .then(response => {
              if(!response.data.status)
                  reject(response.data.msg)

              // commit('SET_REQUESTS', response.data.data)

              resolve(response.data)
          })
          .catch(error => console.log(error))
    })
  },
  getChargesGuardians(ctx , params) {
    return new Promise((resolve, reject) => {
      axios.get(`/reports/school/payments?${params}`)
          .then(response => {
              if(!response.data.status)
                  return reject(response.data.msg)

              return resolve(response.data)
          })
          .catch(err => console.log(err))
    })
  },
}
