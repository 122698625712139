import state from './moduleReportState.js'
import actions from './moduleReportActions.js'
import mutations from './moduleReportMutations.js'
import getters from './moduleReportGetters.js'

export default {
    isRegistered: false,
    namespaced: true,
    state: state,
    actions: actions,
    mutations: mutations,
    getters: getters
}